import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import hhkb from './images/hhkb.jpg';
import eamesStorage from './images/eames-storage.jpg';
import utenSilo from './images/uten-silo.jpg';


const DesignInspirationPage = ({data}) => (
  <Layout>
    <SEO title="Design Inspiration" />
    <h2>Design inspiration</h2>
    <p>
        Here are a bunch of random products, graphic designs, illustrations, etc.
        that I love. Maybe one day I&#39;ll add desriptions why...
    </p>
    <div>
      <img src={eamesStorage} width="300px" />
      <img src={utenSilo} width="300px" />
      <img src={hhkb} />
    </div>
  </Layout>
);

DesignInspirationPage.propTypes = {
  data: PropTypes.object,
};

export default DesignInspirationPage;
